import "../styles/app.scss";

import WebFont from "webfontloader";
import $ from "jquery";
import "slick-carousel";
import "bootstrap";
import "jquery-ui";
import bsCustomFileInput from "bs-custom-file-input";
import "autocomplete.js/dist/autocomplete.jquery.min.js";
import LazyLoad from "vanilla-lazyload";
import "select2";
import "../../node_modules/select2/dist/js/i18n/fr";
import 'bootstrap-datepicker/js/bootstrap-datepicker.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import "jquery-parallax.js";

// MAP Leaflet
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

// BOOTSTRAP GLOBAL
global.$ = global.jQuery = $;

// JS ROUTING
const routes = require("../../public/js/fos_js_routes.json");
global.Routing = require("../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js");
global.Routing.setRoutingData(routes);

// Lazy Loading
const lazyLoadOptions = {
    /* your options here */
};
const pageLazyLoad = new LazyLoad(lazyLoadOptions);

function requireAll(r) {
    r.keys().forEach(r);
}

requireAll(require.context("../images/", true, /\.jpg$/));
requireAll(require.context("../images/", true, /\.png$/));
requireAll(require.context("../images/", true, /\.svg$/));
requireAll(require.context("../images/", true, /\.gif/));

var isLoaded = false;

WebFont.load({
    custom: {
        families: ["Montserrat"],
    },
    fontactive: function(familyName, fvd) {
        if (isLoaded) return;

        $(window).scroll(function() {
            //documentIsScrolled()
        });

        $(window).resize(function() {
            documentIsResized();
        });

        $(document).ready(function() {
            documentIsLoaded();
            isLoaded = true;
        });
    },
});

$(window).on("load", function() {
    //documentIsResized()
});

function documentIsLoaded() {

    // function to collapse accordion listing products
    collapseResponsive();

    // popup
    if (!sessionStorage.getItem('popup') && $('body').find('.wrap-pro').length === 0) {
        var imgPopup = $('#popup img').attr("src")
        if (window.matchMedia("(max-width: 425px)").matches) {
            imgPopup = '/uploads/assets/popup_nl_mobile.jpg'
            $('.popup-window').css('width', '320px')
            $('.popup-window > a').css({'top': '-5px', 'right': '5px', 'font-size': '25px'})
            $('.popup-window .popup-buttons').css({'height': '58px', 'width': '98%'})
            $('.popup-window .popup-buttons a').css({'padding': '7px 7px', 'font-size': '10px'})
        }
        $('#popup img')
            .on('load', function() { 
                $('#popup').show()
                sessionStorage.setItem('popup', true)
            })
            .on('error', function() { 
                console.log("error loading popup, image not loaded");
                $('#popup').hide()
             })
            .attr("src", imgPopup)
        $('#popup .popup-register').on('click', function() {
            $('#popup').hide()
            $('.newsletter_inscription > input').trigger('focus')
            $('html, body').animate({
                scrollTop: $('.newsletter_inscription > input').offset().top - 100
            }, 500);
        })
        $('#popup .popup-close, .popup-overlay').on('click', function() {
            $('#popup').hide()
        })
    }
    
    //appel des pubs en ajax
    $('.banners').each(function() {
        var url = Routing.generate('banniere_list', {
            espace: $(this).data('espace'),
            format: $(this).data('format'),
            annonceurId: $(this).data('annonceur-id'),
            annonceurType: $(this).data('annonceur-type'),
            sousCategorie: $(this).data('sous-categorie')
        });
        $(this).load(url, function() {
            $(this).find('.preloader').remove();
            if ($(this).children().length == 0) {
                $(this).hide();
            }
            if ($(this).parent('.card-list').length > 0 && $(this).children().length == 0) {
                $(this).parent('.card-list').hide();
            }
        });        
    });

    $("nav.navbar ul li.dropdown").hover(
        function() {
            $(this).find("ul.dropdown-menu").stop(true, true).fadeIn(350);
        },
        function() {
            $(this).find("ul.dropdown-menu").stop(true, true).fadeOut(200);
        }
    );

    $(
        "#carousel-produit-une, #carousel-portes-ouvertes, #carousel-produit-nouveau"
    ).carousel({
        interval: 5000,
    });

    // Carousels pubs 10 secondes
    $(
        ".ban-slider"
    ).carousel({
        interval: 10000,
        ride: 'carousel'
    });

    $(
        "#carousel-produit-une.carousel .carousel-item, #carousel-portes-ouvertes.carousel .carousel-item, #carousel-produit-nouveau.carousel .carousel-item"
    ).each(function() {
        var minPerSlide = 2;
        var next = $(this).next();
        if (!next.length) {
            next = $(this).siblings(":first");
        }
        next.children(":first-child").clone().appendTo($(this));

        for (var i = 0; i < minPerSlide; i++) {
            next = next.next();
            if (!next.length) {
                next = $(this).siblings(":first");
            }

            next.children(":first-child").clone().appendTo($(this));
        }
    });

    // js for slick slide clone element for loop
    var slideCount = $(".slide").length;
    if (slideCount <= 3) {
        // clone element
        $(".regular.slider .slick-list .slick-track")
            .children()
            .clone(true, true)
            .appendTo(".regular.slider .slick-list .slick-track");
    }
    // init slick js to controlle reponsive ....

    $(".regular").slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3.999,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerPadding: "0px",
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    Infinity: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    Infinity: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    Infinity: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });

    $(".regular-v2").slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerPadding: "0px",
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    Infinity: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    Infinity: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    Infinity: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });

    $(".categories").select2({
        width: "100%",
        placeholder: "Catégories",
        language: "fr",
    });
    $(".localisation").select2({
        width: "100%",
        placeholder: "Localisation",
        language: "fr",
    });
    $(".disponibilite").select2({
        width: "100%",
        placeholder: "Disponibilité",
        language: "fr",
    });

    // Remove your custom active class active slide finished
    $(".slider").on("afterChange", function(e, slick, currentSlide, nextSlide) {
        $(".slick-clone-current").removeClass("slick-clone-current");
    });

    // custom auto height for iframe on bas of content
    var frame = window.frames["news-detail"];
    if (frame) {
        frame.style.height = frame.contentWindow.document.body.scrollHeight + "px";
        frame.style.width = frame.contentWindow.document.body.scrollWidth + "px";
    }

    // hide bloc-pub-sidebar if empty
    $('.bloc-pub-sidebar').each(function() {
        if ($(this).height() == 0) {
            $(this).hide();
        }
    });

    $('a.block-filter').on('click', function() {
        if ($('div#block-filter').hasClass('show')) {
            $('a.block-filter').removeClass('rotate');
        }
        else{
            $('a.block-filter').addClass('rotate');
        }
    });

    // text seo
    $(".text-seo .show-more a").on("click", function() {
        const textSeo = $(".text-seo div.content");
        textSeo.hasClass("opened") || (textSeo.addClass("opened").animate({"max-height": "9000px"}), $(this).parent().hide());
    });

    if($(".text-recherche .content p").innerHeight() > '30') { 
        $(".text-seo .show-more a").css("display", "block");
    } else if ($(".text-recherche .content p").innerHeight() < '30') { 
        $(".text-seo .show-more a").css("display", "none");
    }

    // annuaire - fiche fournisseur
    $('.fiche-block-gris .pill-fiche').on('click', function () {
        var anchor = $(this).attr('href');
        var speed = 750;
        if (window.matchMedia('(max-width: 425px)').matches) {
            $('html, body').animate( { scrollTop: $(anchor).offset().top - 99 }, speed );
        } else if (window.matchMedia('(max-width: 1024px)').matches) {
            $('html, body').animate( { scrollTop: $(anchor).offset().top - 90 }, speed );
        } else {
            $('html, body').animate( { scrollTop: $(anchor).offset().top - 60}, speed );
        }
    })
    
    mobileRendering(window.matchMedia("(max-width: 1024px)").matches);
}

function documentIsResized() {
    console.log('Document Resized');

    if (window.matchMedia("(max-width: 768px)").matches) {
        collapseResponsive();
    }

    if (window.matchMedia("(max-width: 992px)").matches) {} else {
        if ($("header").hasClass("fullHeight")) {
            $("header").removeClass("fullHeight");
        }
        $("header").css("overflow-y", "visible");
        $("header .navbar-toggler span")
            .removeClass("navbar-toggler-cross")
            .addClass("navbar-toggler-icon");
        $("header .navbar-toggler").attr("aria-expanded", false);
        $("header .navbar-collapse").removeClass("show");
        $("header .navbar-nav").removeClass("done");
        $("header .submenu-list").removeClass("shown");

        $("header .navbar-toggler-account").attr("aria-expanded", false);

        $("header .navbar ul.navbar-nav > li.has-megamenu div.sub-menu")
            .addClass("dropdown-menu")
            .removeClass("sub-menu shown done");

        if ($("div.dropdown-menu").find(".back").length != 0) {
            var _back = $("div.dropdown-menu").find(".back");
            _back.remove();
        }

        if ($("div.dropdown-menu").find(".parent-page").length != 0) {
            var _parent = $("div.dropdown-menu").find(".parent-page");
            _parent.remove();
        }
        /*if ($("header .navbar-collapse").find(".connexion").length === 0) {
            $(".connexion")
                .addClass("navbar-nav text-right pl-0 pr-0")
                .insertAfter($("header .navbar-collapse .navbar-nav"));
        }*/

        if ($("div.dropdown-menu").find(".parent-page").length != 0) {
            var _parent = $("div.dropdown-menu").find(".parent-page");
            _parent.remove();
        }
        /*if ($("header .navbar-collapse").find(".connexion").length === 0) {
            $(".connexion")
                .addClass("navbar-nav text-right pl-0 pr-0")
                .insertAfter($("header .navbar-collapse .navbar-nav"));
        }*/
    }

    mobileRendering(window.matchMedia("(max-width: 1024px)").matches);
}

function mobileRendering(state) {
    // console.log("Mobile Rendering");

    if (state === true) {
        if (window.matchMedia("(max-width: 992px)").matches) {
            /*$(".connexion")
                .removeClass("navbar-nav text-right pl-0 pr-0")
                .insertBefore($("button.navbar-toggler"));*/

            $(".item-compte")
                .addClass("d-none");


            $("header .navbar-toggler").on("click", function() {
                if ($("header").hasClass("fullHeight")) {
                    $("header").removeClass("fullHeight");
                    if($(".navbar-toggler-account").hasClass("d-none")){
                        $(".navbar-toggler-account").removeClass("d-none");
                    }
                } else {
                    $("header").addClass("fullHeight");
                    if(!$(".navbar-toggler-account").hasClass("d-none")){
                        $(".navbar-toggler-account").addClass("d-none");
                    }
                }

                var burger = $(this).find("span");
                if (burger.hasClass("cross")) {
                    burger.removeClass("cross");
                } else {
                    burger.addClass("cross");
                }
            });

            $("header .navbar-toggler-account").on("click", function() {
                if ($("header").hasClass("fullHeight")) {
                    $("header").removeClass("fullHeight");
                    if($(".navbar-toggler").hasClass("d-none")){
                        $(".navbar-toggler").removeClass("d-none");
                    }
                } else {
                    $("header").addClass("fullHeight");
                    if(!$(".navbar-toggler").hasClass("d-none")){
                        $(".navbar-toggler").addClass("d-none");
                    }
                }

                var burger = $(this).find("span");
                if (burger.hasClass("cross")) {
                    burger.removeClass("cross");
                } else {
                    burger.addClass("cross");
                }
            });

            $("header").css("overflow-y", "scroll");

            $("header .navbar ul.navbar-nav > li.has-megamenu div.dropdown-menu")
                .addClass("sub-menu")
                .removeClass("dropdown-menu");

            $("header .navbar ul.navbar-nav > li.has-megamenu > a").each(function() {
                if (
                    $(this).next("div.sub-menu").find(".back").length === 0 &&
                    $(this).next("div.sub-menu").find(".parent-page").length === 0
                ) {
                    $(this).unbind("click");
                    $(this).bind("click", function() {
                        $(this).next("div.sub-menu").addClass("shown");
                        $("header .navbar-nav").addClass("done");
                        return false;
                    });

                    var _link = $(this).attr("href");
                    var _parent = $(
                        '<span class="parent-page"><a href="' +
                        _link +
                        '">' +
                        $(this).text() +
                        "</a></span>"
                    );
                    _parent.prependTo($(this).next("div.sub-menu"));

                    var _back = $('<span class="back">Retour</span>');
                    _back.prependTo($(this).next("div.sub-menu"));

                    _back.unbind("click");
                    _back.bind("click", function() {
                        $("header .navbar-nav").removeClass("done");
                        $("header .navbar ul.navbar-nav > li.has-megamenu > a")
                            .next("div.sub-menu")
                            .removeClass("shown");
                        return false;
                    });
                }
                if ($(this).next("div.sub-menu").find($("ul.submenu-list"))) {
                    $(this)
                        .next("div.sub-menu")
                        .find("div.submenu > span.dropdown-header")
                        .each(function() {
                            if ($(this).next("ul.submenu-list").find(".back").length === 0) {
                                $(this).unbind("click");
                                $(this).bind("click", function() {
                                    $(this).next("ul.submenu-list").addClass("shown");
                                    $(this)
                                        .closest("div.sub-menu")
                                        .removeClass("shown")
                                        .addClass("done");
                                    return false;
                                });

                                var _parent = $(
                                    '<li class="parent-page">' + $(this).html() + "</a></li>"
                                );
                                _parent.prependTo($(this).next("ul.submenu-list"));

                                var _back = $('<li class="back">Retour</li>');
                                _back.prependTo($(this).next("ul.submenu-list"));

                                _back.unbind("click");
                                _back.bind("click", function() {
                                    $(this)
                                        .closest("div.sub-menu")
                                        .removeClass("done")
                                        .addClass("shown");
                                    $("div.submenu > span.dropdown-header")
                                        .next("ul.submenu-list")
                                        .removeClass("shown");
                                    return false;
                                });
                            }
                        });
                }
            });
        } else {
            if($(".item-compte").hasClass("d-none")){
                $(".item-compte").removeClass("d-none");
            }

            $("header").css("overflow-y", "visible");
        }
    }
}

$(document).ready(function() {
    $('.js-datepicker').datepicker({
        format: 'dd/mm/yyyy h:mm',
        language: 'fr',
        defaultTime:'00:00'
    })

    //Input file
    bsCustomFileInput.init();



    // Autocomplete AD,Evenement,Formation,
    $("#search-anden")
        .autocomplete({
            hint: false,
        }, [{
            source: function(query, cb) {
                let context = $("#search-context").val();

                $.ajax({
                    url: Routing.generate("autocompletion", {
                        string: query,
                        context: context,
                    }),
                }).then(function(data) {
                    cb(data.results);
                });
            },
            displayKey: "nom",
            minLength: 3,
            debounce: 10,
        }, ])
        .on(
            "autocomplete:selected",
            function(event, suggestion, dataset, context) {
                window.location.href = suggestion.url;
            }
        );

    // NEW Autocomplete with X instances
    $(document).ready(function() {
        $(".search-anden").each(function() {
            let $searchAnden = $(this);

            $searchAnden.autocomplete({
                hint: false,
            }, [{
                source: function(query, cb) {
                    // Trouver l'élément suivant ayant la classe search-context
                    let context = $searchAnden.closest('div').find(".search-context").val();

                    $.ajax({
                        url: Routing.generate("autocompletion", {
                            string: query,
                            context: context,
                        }),
                    }).then(function(data) {
                        cb(data.results);
                    });
                },
                displayKey: "nom",
                minLength: 3,
                debounce: 10,
            }])
                .on("autocomplete:selected", function(event, suggestion, dataset, context) {
                    window.location.href = suggestion.url;
                });




        });
    });


    // BTN Search AD
    $(".btn-search-mp").click(function() {
        let keyword = $("#search-anden").val();
        let context = $("#search-context").val();

        if (keyword === '') {
            return false;
        }

        const url = new URL(window.location);
        window.history.pushState({},'',url);

        if (context === "cd" || context === "lp" || context === "fd") {

            window.location.replace(Routing.generate("annuaire_search", {
                string: keyword,
                context: context,
            }));

        } else if (context === "evenement") {

            window.location.replace(Routing.generate("evenement_search", {
                string: keyword
            }));

        } else if (context === "formation") {

            window.location.replace(Routing.generate("formation_search", {
                string: keyword
            }));

        } else if(context === "ed") {

            window.location.replace(Routing.generate("expo_dentaire_search", {
                string: keyword
            }));
        }


    });

    //Search with enter button
    $("#search-anden").on('keyup', function(e) {

        if ($("#search-anden").val() === '') {
            return false;
        }

        const url = new URL(window.location);
        window.history.pushState({},'',url);

        if (e.key === 'Enter' || e.keyCode === 13) {
            let keyword = $("#search-anden").val();
            let context = $("#search-context").val();

            if (context === "cd" || context === "lp" || context === "fd") {

                window.location.replace(Routing.generate("annuaire_search", {
                    string: keyword,
                    context: context,
                }));

            } else if (context === "evenement") {

                window.location.replace(Routing.generate("evenement_search", {
                    string: keyword
                }));

            } else if (context === "formation") {

                window.location.replace(Routing.generate("formation_search", {
                    string: keyword
                }));

            } else if(context === "ed") {

                window.location.replace(Routing.generate("expo_dentaire_search", {
                    string: keyword
                }));
            }
        }
    });
});

// call ajax for newsletter nscription
$(document).ready(function() {

    $(".newsletter_inscription").submit(function(e) {
        e.preventDefault();
        var data = $(".newsletter_inscription input").val();
        // this will call this function for controlle idf this email is valide or not
        if (validateEmail(data)) {
            $.ajax({
                type: "POST",
                url: "/newsletter/inscription",
                data: data,
                success: function(data) {
                    // $("#results").fadeIn("slow", function() {
                    //     $("#results").text(data["results"]);
                    //     $("#results").delay(10000).fadeOut("slow");
                    // });

                    $('#results').fadeIn().html(data["results"]);
                    setTimeout(function() {
                        $('#results').fadeOut("Slow");
                    }, 10000);
                },
            });
        } else {
            $("#results").fadeIn();
            $("#results").text("Veuillez saisir une adresse mail valide");
            $("#results").delay(10000).fadeOut(80);
        }
    });

    // Bloc "produit.html.twig" full clickable
    $(".container-list > .card, .container-list > .card-list,.container-list >  .card-list-mp,.container-list >  .card-horyzontal,.container-list >  .card-pro, .carousel-item > .card, .card.home-item-produit.border-0.w-100").click(function() {
        var url = $(this).find("a").attr("href");
        var target = $(this).find("a").attr("target");
        if (url) {
            // # open in new window if "_blank" used
            if (target == '_blank') {
                window.open(url, target);
            } else {
                window.location = url;
            }
        }
        return false;
    });

    // Bloc "produit.html.twig" - option pour l'admin seulement
    $(".div-for-admin").click(function(e) {
        e.stopPropagation(); // Annuler l'eve précédent (ligne 722)
        var url = $(this).find("a").attr("href");
        window.location = url;
        return false;
    });

    // le code ci-dessus ne s'applique plus au slider en mode responsive suite à un bug du plugin slick-slider
    // https://github.com/kenwheeler/slick/issues/1015
    // TODO : remplacer slick slider par qq d'autre plus récent !!
    $('.carousel-produit-nouveau').on('click', '.carousel-item > .card', function() {
        var url = $(this).find("a").attr("href");
        var target = $(this).find("a").attr("target");
        if (url) {
            // # open in new window if "_blank" used
            if (target == '_blank') {
                window.open(url, target);
            } else {
                window.location = url;
            }
        }
        return false;
    });



});
// place the btn on same line
$(".algolia-autocomplete").style = "display: unset !important";

// function for controle if the email valide or not
function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

// function with accordion listing products 
function collapseResponsive() {
    // display mobile products
    if (window.matchMedia('(max-width: 768px)').matches) {
        $('#accordionTitle').attr('data-toggle', 'collapse');
        $('#accordionTitle').attr('data-target', '#collapseItems');
    } else {
        $('#accordionTitle').removeAttr('data-toggle');
    }
}

$(".btn-cat").on("click", function() {
    var id = $(this).data("id");
    var catid = "#cat-" + id;
    if ($(".hide").css("display", "none")) {
        $(catid + " .expandable-list .hide").css("display", "block");
        $(catid + ".col-md-12 .expandable-list .hide").css("display", "block");
        $("#btn-cat-more-" + id).addClass("hide");

        $("#btn-cat-" + id).removeClass("btn-hide");
    }
});
$(".btn-cat-mois").on("click", function() {
    var id = $(this).data("id");
    if (($(".hide").style = "display:block")) {
        $("#btn-cat-more-" + id).removeClass("hide");
        $(".hide").css("display", "none");
        var btn = "#btn-cat-" + id;
        $(btn).addClass("btn-hide");
    }
});

$(".btn-lp").on("click", function() {
    var id = $(this).data("id");
    var catid = "#lp-" + id;
    if ($(".hide").css("display", "none")) {
        $(catid + " .expandable-list .hide").css("display", "block");
        $(catid + ".col-md-12 .expandable-list .hide").css("display", "block");
        $("#btn-lp-more-" + id).addClass("hide");
        $("#de-lp-mois-lp").removeClass("btn-hide");
    }
});
$(".btn-lp-mois").on("click", function() {
    var id = $(this).data("id");
    if (($(".hide").style = "display:block")) {
        $("#btn-lp-more-" + id).removeClass("hide");
        $(".hide").css("display", "none");
        $("#de-lp-mois-lp").addClass("btn-hide");
    }
});

$(".btn-ds").on("click", function() {
    var id = $(this).data("id");
    var catid = "#ds-" + id;
    if ($(".hide").css("display", "none")) {
        $(catid + " .expandable-list .hide").css("display", "block");
        $(catid + ".col-md-12 .expandable-list .hide").css("display", "block");
        $("#btn-ds-more-" + id).addClass("hide");
        $(".btn-ds-mois").removeClass("btn-hide");
    }
});
$(".btn-ds-mois").on("click", function() {
    var id = $(this).data("id");
    if (($(".hide").style = "display:block")) {
        $("#btn-ds-more-" + id).removeClass("hide");
        $(".hide").css("display", "none");
        $("#de-ds-mois").addClass("btn-hide");
    }
});

/*
$.ajax({
                type: "POST",
                url: "/newsletter/inscription",
                data: data,
                success: function(data) {
                    // $("#results").fadeIn("slow", function() {
                    //     $("#results").text(data["results"]);
                    //     $("#results").delay(10000).fadeOut("slow");
                    // });

                    $('#results').fadeIn().html(data["results"]);
                    setTimeout(function() {
                        $('#results').fadeOut("Slow");
                    }, 10000);
                },
            });
*/

/* delete notification */
$('.btn-delete-notif').on("click",function () {
    event.preventDefault();
    var id = $(this).data('id');
    var obj = $(this);

    $.ajax({
        url: Routing.generate("ajax-notification-delete", {
            id: id,
        }),
    }).then(function(data) {
        obj.parent('div').fadeOut();
        var count = $(".count-notif").text();
        count = count-1;

        $(".count-notif").html(count);

    });

});

$(".add-fav").on("click", function(e){
    e.preventDefault();
    console.log($(this).data("id"));
    //console.log($(this).data("type"));

    var type = $(this).data("type");
    var id = $(this).data("id");
    var obj = $(this);

    if(type === 'user-add'){

        console.log('user-add');

        $.ajax({
            url: Routing.generate("ajax-friendship-add", {
                to: id,
            }),
        }).then(function(data) {
            console.log(data);

            $('#modalConfirm .modal-confirm-text').html('Demande de contact envoyée');
            $('#modalConfirm').modal('show');
            obj.data('type','user-pending');
            obj.text('Demande de contact envoyée');

        });


    } else if(type === 'user-remove'){

        console.log('remove-add');
        obj.data('type','user-add');
        $('.remove-confirmation').data('id',id);

        $('#modalConfirmRemoveContact').modal('show');


    } else if(type === 'user-remove-confirmation'){

        $.ajax({
            url: Routing.generate("ajax-friendship-remove", {
                to: id,
            }),
        }).then(function(data) {
            console.log(data);

            $('#modalConfirm .modal-confirm-text').html('Contact supprimé avec succès');
            $('#modalConfirmRemoveContact').modal('hide');
            $('#modalConfirm').modal('show');
            obj.data('type','user-add');
            obj.text('Ajouter à mes contacts');

        });


    } else {
        // Type
        // fav-produit
        // fav-lp
        // fav-fd
        // fav-event
        // fav-annonce
        // fav-emploi
        // fav-formation
        // fav-user-pro
        console.log('toggle favori');

        $.ajax({
            url: Routing.generate("ajax-toggle-favori", {
                type: type,
                id: id,
            }),
        }).then(function(data) {
            console.log(data);

            var txt = '';

            if (type === 'fav-lp' || type === 'fav-fd' || type === 'fav-user-pro'){

                if(data === 1){
                    var txt = 'Ajout au carnet d\'adresse avec succès';
                    obj.text('Supprimer du carnet d\'adresse');
                } else {
                    var txt = 'Suppression du carnet d\'adresse avec succès';
                    obj.text('Ajouter au carnet d\'adresse');
                }


            } else if (type === 'fav-event' || type === 'fav-emploi' || type === 'fav-formation' || type === 'fav-produit'){

                if(data === 1){
                    var txt = 'Ajout aux favoris avec succès';
                    obj.text('Supprimer des favoris');
                } else {
                    var txt = 'Suppression des favoris avec succès';
                    obj.text('Ajouter aux favoris');
                }


            } else if (type === 'fav-annonce' ){

                if(data === 1){
                    var txt = 'Annonce sauvegardée avec succès';
                    obj.text('Supprimer des annonces sauvegardées');
                } else {
                    var txt = 'Suppression de l\'annonce sauvegardée avec succès';
                    obj.text('Sauvegarder cette annonce');
                }

            }

            //$('#modalConfirm .modal-title').append('Hello');
            if(txt){
                $('#modalConfirm .modal-confirm-text').html(txt);
                $('#modalConfirm').modal('show');
            }

        });

    }

});

// Indique l'état des favoris / carnet d'adresse sur une fiche
$(".add-fav").each(function(e){
    var type = $(this).data("type");
    var id = $(this).data("id");

    var apiResult = 0;
    var result = null;

    var div = $(this);

    $.ajax({
        url: Routing.generate("ajax-toggle-favori-status", {
            type: type,
            id: id,
        }),
    }).then(function(data) {
        console.log(data);
        apiResult = data;

        // Type
        // user-add
        // user-remove
        // fav-produit
        // fav-lp
        // fav-fd
        // fav-event
        // fav-annonce
        // fav-emploi
        // fav-formation
        // fav-user-pro
        if(type === "user-add" && apiResult === 2 ){
            result = 'Demande de contact envoyée';
            div.data('type','user-waiting');
        } else if(type === "user-add" && apiResult === 1 ){
            result = 'Supprimer de mes contacts';
            div.data('type','user-remove');
        } else if(type === "user-add" && apiResult === 3 ){
            div.remove();
            div.data('type','user-refused');
        } else if(type === "user-remove" && apiResult === 1) {
            result = 'Ajouter à mes contacts';
            div.attr('data-type','user-add');
        } else if(type === "fav-produit" && apiResult === 1) {
            result = 'Supprimer des favoris';
        } else if(type === "fav-lp" && apiResult === 1) {
            result = 'Supprimer du carnet d\'adresse';
        } else if(type === "fav-fd" && apiResult === 1) {
            result = 'Supprimer du carnet d\'adresse';
        } else if(type === "fav-event" && apiResult === 1) {
            result = 'Supprimer des favoris';
        } else if(type === "fav-annonce" && apiResult === 1) {
            result = 'Supprimer des annonces sauvegardées';
        } else if(type === "fav-emploi" && apiResult === 1) {
            result = 'Supprimer des favoris';
        }else if(type === "fav-user-pro" && apiResult === 1) {
            result = 'Supprimer du carnet d\'adresse';
        }

        if(apiResult > 0){
            //console.log($(this).parent);
            div.text(result);
        }
    });


});


// modal landing CD
$('#exampleModalCD').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) 
    var title = button.data('title') 
    var image = button.data('image') 
    var modal = $(this)

    modal.find('.modal-content img').attr('src', image);
    modal.find('.modal-title').text('Exemple de l\'écran de "' + title + '" de MyDentaire')
  })
